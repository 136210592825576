<template>
  <div class="detail container">
    <!-- <img :src="info.mainImg" alt="" class="cover" /> -->
    <p class="time">{{ info.createTime }}</p>
    <h3 class="title">{{ info.title }}</h3>
    <p class="author">
      <img v-if="info.sourceIcon" :src="info.sourceIcon" alt="" />{{
        info.source
      }}
    </p>
    <div class="content" v-html="info.content"></div>
  </div>
</template>

<script>
import { newsDetail } from "@/api/news";

export default {
  data() {
    return {
      info: {},
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$loading.show();
      newsDetail(this.$route.query.newsId).then((res) => {
        this.$loading.hide();
        if (res.code == 200) {
          this.info = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.detail {
  position: relative;
  padding: 30px 0;
  min-height: calc(100vh - 66px - 200px);
  text-align: left;
  .time {
    margin-bottom: 5px;
    color: #d1d1d1;
    font-size: 16px;
  }
  .title {
    font-size: 32px;
    color: #27282d;
  }
  .author {
    margin: 15px 0;
    font-size: 16px;
    img {
      margin-right: 10px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
    }
  }
  ::v-deep.content {
    p {
      line-height: 1.5;
    }
    a {
      color: royalblue !important;
    }
    img {
      margin: 10px 0;
      width: 100% !important;
    }
  }
}
</style>